.label-text {
    display: inline-block;
    text-align: right;
    padding-right: 10px;
    color: $ci-color-7;
    font-style: italic;
    font-weight: 300;
    line-height: $form-element-height;
    vertical-align: middle;
    font-size: 14px;
}

.nx-dropdown-menu ul {
    width: 100%;
    text-align: center;
}

.custom-dropdown {
    position: relative;

    &.disabled {
        background-color: $disabled-background-color;

        .nx-dropdown-button {
            background-color: $disabled-background-color;

            &:hover {
                cursor: default;
            }

            &:focus {
                box-shadow: none;
                border-color: $ci-color-10;
                border-bottom: $colored-border-thickness solid $ci-color-10;
            }

            .fa-caret-down {
                color: $ci-color-10;
                background-color: $disabled-background-color;
            }

            &.invalid {
                border-bottom-color: $ci-color-5;
            }
        }
    }

    .nx-dropdown-button {
        position: relative;
        border: 1px solid $ci-color-10;
        border-bottom: $colored-border-thickness solid $ci-color-5;
        background-color: white;
        height: $form-element-height;
        font-size: $form-element-fontsize;
        width: 100%;
        padding: 0 30px 0 10px;
        text-align: left;
        overflow: hidden;
        @include transition(all 0.30s ease-in-out);

        &:hover {
            cursor: pointer;
        }

        .fa-caret-down {
            position: absolute;
            right: 0;
            top: 0;
            font-size: 18px;
            width: 30px;
            height: $form-element-height;
            line-height: $form-element-height;
            background-color: $color-white;
            color: $ci-color-1;
            @include transition(all 0.30s ease-in-out);
        }

        span.selected {
            color: unset;
            background-color: transparent !important;
        }

        span.placeholder-text {
            color: $ci-color-7;
            padding-right: 30px;
        }

        &:active, &:focus {
            outline: none;
            box-shadow: 0 0 5px $nx-input-focus-shadow;
            border: 1px solid $nx-input-focus-shadow;
        }

        &.with-label {
            display: inline-block;
            width: unset;
        }

        &.invalid {
            border-bottom-color: $color-error;
        }
    }

    .nx-dropdown-menu {

        ul {
            position: absolute;
            top: $form-element-height;
            margin: 0;
            padding: 0;
            list-style-type: none;
            background-color: $ci-color-4;
            //width: 100%;
            border: 1px solid $ci-color-5;
            z-index: 99;
            max-height: 400px;
            overflow-y: auto;

            li {
                font-size: 12px;
                padding: 5px 10px;
                @include transition(color 0.2s ease-in-out);

                &:hover {
                    cursor: pointer;
                    color: $ci-color-1;
                }

                &:focus, &:active {
                    outline: none;
                }
            }
        }
    }

}
