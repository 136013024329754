.ifms-templates {

}

.ifms-templates__button-add-template {
  @extend .nx-btn;
  margin: 20px 4px;
}

.vehicle-templates-frame {
  padding: 7px 15px;
}

.vehicle-template {
  display: inline-block;
  width: 200px;
  margin: 3px;

  border-radius: 3px;
  border: 1px solid rgba(0,0,0,.03);
  box-shadow: 0 2px 2px rgba(0,0,0,.24), 0 0 2px rgba(0,0,0,.12);

  &:hover:not([disabled]) {
    cursor: pointer;
    background-color: transparent;
    background-color: rgba(0, 0, 0, 0.03);
  }

  img {
    display: inherit;
  }

  &.add-template {
    display: flex;
    align-items: center;

    &> div {
      width: 100%;
    }
  }

  .fleet-vehicle-card-header {
    margin-top: 8px;
    margin-bottom: 24px;

    .card-property {
      padding: 0px 16px;
      color: rgb(117, 117, 117);
      opacity: .87;
    }

    .title {
      color: #212121;
      margin-bottom: 4px;
      font-weight: bold;
      font-size: 18px;
      opacity: .87;
    }

    .sub-title {

    }

  }

  &.horizontal-vehicle-template {
    width: 100%;

    .fleet-vehicle-card-header {
      padding-right: 0;

      .card-property {
        // padding: 0;
      }
    }

    &:hover:not([disabled]) {
      cursor: default;
      background-color: inherit;
      background-color: inherit;
    }

  }
}

@media screen and (-ms-high-contrast:active), (-ms-high-contrast:none) {
  .vehicle-template img {
    display: inline-block;
    margin-top: -36px;
    margin-bottom: -40px;
  }
}
