.search-filters-dropdown-container {

  display: block;
  width: 100%;

  input {
    margin-bottom: 10px;
  }

  .dropdown-title {
    font-size: 18px;
    color: var(--ci-color-1);
  }

  .button-container {
    position: relative;
    z-index: 802;
  }

  button {
    background-color: var(--ci-color-1);
    color: white;
    display: block;
    margin: 16px auto 0;

    &:focus {
      outline: none;
    }

    &:active {
      outline: none;
    }
  }

  .search-filters-dropdown-selected {
    padding: 5px 8px 5px 8px;
    height: 39px;
    overflow: hidden;
    background-color: white;
    outline: 1px solid #ebebeb;
    border-bottom: 2px solid var(--ci-color-1);
    font-size: 14px;
    margin: 10px 0;
    color: #898989;
    position: relative;
    display: flex;
    text-align: left;

    .list {
      width: 90%;
      font-weight: 400;
      line-height: 2;
    }

    .list-color {
      color: var(--ci-color-1);
    }

    .item-count {
      text-align: center;
    }

    .icon {
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      width: 10%;
      text-align: right;

      .fa-sort-down {
        color: green;
        line-height: 0.7;
      }

      i {
        font-size: 24px;
        line-height: 1.2;
        color: red;
      }
    }

  }

  .search-filters-dropdown-list-container {

    position: relative;
    width: 100%;

    .search-filters-dropdown-list {

      width: 100%;

      background-color: white;
      max-height: 200px;
      overflow-y: scroll;
      position: absolute;
      z-index: 800;

      box-shadow: 0px 2px 6px 0px #bbb6b6;

      .icon {
        padding-right: 8px;
        color: red;
        cursor: pointer;
      }

      label {
        cursor: pointer;
        display: block;
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        margin: 0;
      }

      i {
        font-size: 18px;
      }

      input[type="checkbox"] {
        position:absolute;
        opacity: 0;
        height: 0;
        width: 0;
        cursor: pointer;
      }

      li {
        cursor: pointer;
        list-style-type: none;
        padding: 4px 8px;
        margin: 0 16px;

        &:first-child {
          margin-top: 8px;
        }

        &:not(:last-child) {
          border-bottom: 1px solid #ccc;
        }

        &.selected {
          .icon {
            color: #94c11f;
          }
        }

        &:hover {
          color: var(--ci-color-1);
          background-color: #e3f2fd;
        }
      }
    }
  }
}
