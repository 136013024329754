@import '~bootstrap/scss/functions';
@import "variables";
@import "~bootstrap/scss/mixins";

.nx-btn {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    background-color:  $nx-btn-background;
    border: 1px solid $nx-btn-border;
    height: $form-element-height;
    line-height: $form-element-height;
    padding: 0 10px;
    font-size: $form-element-fontsize;
    color: $nx-btn-font;
    min-width: 150px;
    @include transition(all 0.2s ease-in-out);
    font-weight: 300;

    &:hover {
        cursor: pointer;
        background-color: $nx-btn-hover-background;
        color: $nx-btn-hover-font;
        border-color: $nx-btn-hover-border;
    }

    &:active, &:focus {
        outline: none;
    }

    &:disabled {
        background-color: $ci-color-9;
        color: $ci-color-7;
        border-color: $ci-color-9;

        &:hover {
            cursor: default;
            background-color: $ci-color-9;
            color: $ci-color-7;
            border-color: $ci-color-9;
        }
    }
}

.nx-btn-flex {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    background-color: $nx-btn-background;
    width: 100%;
    border: 1px solid $nx-btn-border;
    height: $form-element-height;
    line-height: $form-element-height;
    padding: 0 10px;
    font-size: $form-element-fontsize;
    color: $nx-btn-font;
    @include transition(all 0.2s ease-in-out);
    font-weight: 300;

    &:hover {
        cursor: pointer;
        background-color: $nx-btn-hover-background;
        color: $nx-btn-hover-font;
        border-color: $nx-btn-hover-border;
    }

    &:active, &:focus {
        outline: none;
    }

    &:disabled {
        background-color: $ci-color-9;
        color: $ci-color-7;
        border-color: $ci-color-9;

        &:hover {
            cursor: default;
            background-color: $ci-color-9;
            color: $ci-color-7;
            border-color: $ci-color-9;
        }
    }
}

.nx-small-btn {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    border: 1px solid $nx-icon-btn-border;
    background-color: $color-white;
    color: $color-grey-dark;
    height: 28px;
    line-height: 26px;
    width: 70px;
    font-size: 14px;
    text-align: center;
    z-index: 9;
    display: block;
    position: relative;
    float: right;
    margin-right: 7px;
    padding: 0;
    @include transition(all 0.2s ease-in-out);

    &:hover {
        cursor: pointer;
        background-color: $nx-icon-hover-background;
        color: $nx-icon-hover-font;
    }

    &:disabled {
        background-color: $ci-color-9;
        color: $ci-color-7;
        border-color: $ci-color-9;

        &:hover {
            cursor: default;
            background-color: $ci-color-9;
            color: $ci-color-7;
            border-color: $ci-color-9;
        }
    }

    &:focus {
        outline: none;
    }

    &.selected {
        background-color: $nx-icon-hover-background;
        color: $nx-icon-hover-font;
    }
}

.nx-extra-small-btn {
    @extend .nx-small-btn;
    width: 30px;

    &.active {
        background: $ci-color-1;
        color: $color-white;

        &:hover {
            color: $color-grey-dark;
            background: $color-white;
        }
    }
}

.nx-btn-non-cta {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    @extend .nx-btn;
    border: 1px solid $nx-icon-btn-border;
    background-color: $color-white;
    color: $color-grey-dark;

    &:hover {
        cursor: pointer;
        background-color: $nx-icon-hover-background;
        color: $nx-icon-hover-font;
    }

    &:disabled {
        background-color: $ci-color-9;
        color: $ci-color-7;
        border-color: $ci-color-9;

        &:hover {
            cursor: default;
            background-color: $ci-color-9;
            color: $ci-color-7;
            border-color: $ci-color-9;
        }
    }
}

.ui-autocomplete {

    .ui-autocomplete-input {
        width: 80% !important;
        border-right: none;
        height: 39px;
        font-size: 14px;
        padding: 0 30px 0 10px;
        border-bottom: $colored-border-thickness solid $ci-color-1;

        &:hover {
            border-bottom: $colored-border-thickness solid $ci-color-1;
        }

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    .ui-button-icon-only {
        width: 20% !important;
        height: 39px;
        background-color: transparent;
        color: #ccc;
        border-radius: 0;
        border-color: #cccccc;
        border-left: none;
        border-bottom: $colored-border-thickness solid $ci-color-1;

        &:hover {
            border-bottom: $colored-border-thickness solid $ci-color-1;
        }

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

}

.fast-link {
    margin-bottom: 10px;
    color: $ci-color-7;
    @include transition(all 0.2s ease-in-out);

    &:hover {
        cursor: pointer;
        color: $ci-color-1;
    }

    &.button {
        background: none;
        border: none;
        font-weight: 300;
        padding: 0;
    }

    &.detail-center {
        margin-left: 24%;

        @include media-breakpoint-up(md) {
            margin-left: 29%;
        }

        @include media-breakpoint-up(lg) {
            margin-left: 25%;
        }

        @include media-breakpoint-up(xl) {
            margin-left: 30%;
        }
    }
}

.highlight-header {
    font-size: 20px;
    color: $ci-color-2;
    font-weight: 400;
    margin-bottom: 15px;
}

.button-bar-right {
  float: right;

  button {
    &:hover {
      cursor: pointer;
    }

    &:not(:last-child) {
      margin-right: 4px;
    }
  }
}



//Bootstrap Overwrite
.btn-toolbar {
    flex-wrap: nowrap;

    button {
        background-color: #fff;
        border-color: #adadad;
        color: #000;
        outline: none;
        box-shadow: none;

        &:hover {
            background-color: darken(#fff, 10%);
            color: #000;
            box-shadow: none !important;
        }

        &:active {
            background-color: #fff;
            color: #000;
            outline: none;
            box-shadow: none !important;
        }

        &:focus {
            background-color: #fff;
            color: #000;
            outline: none;
            box-shadow: none !important;
        }

        i {
            min-width: 15px;
        }
    }
}
