nav.menu {
  color: $ci-color-13;

  @include media-breakpoint-down(lg) {
    // Solution from https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    // End solution

    border-top: 4px solid white;
    overflow: auto;

    > ul > li:not(:last-child) {
      border-bottom: 1px solid $ci-color1-darken10;
    }

    div > ul {
      padding-top: 8px;
    }
  }

  @include media-breakpoint-between(md, lg) {
    border-top: 0;
    min-width: 350px;
    background: $ci-color-1;
    right: 0;
    position: fixed;
    padding: 0 20px;
  }
}

nav.menu {
  ul {
    padding: 0;
    margin: 0;

    @include media-breakpoint-up(xl) {
      display: flex;
      justify-content: flex-end;
    }
  }

  > ul {
    @include media-breakpoint-down(lg) {
      padding-bottom: 80px;
    }
  }
}

nav.menu ul li {
  list-style-type: none;

  @include media-breakpoint-up(xl) {
    position: relative;
    text-align: center;
    min-width: 120px;
    max-width: 180px;
    white-space: nowrap;
    transition-duration: .3s;
  }

  a, div {
    background: $ci-color-1;
    padding: 19px 10px 18px;
    display: block;
    text-decoration: none;
    color: $ci-color-13;

    @include media-breakpoint-up(xl) {
      padding: 17px 10px 18px;
    }
  }

  div > span {
    display: block;
    width: 100%;
  }

  i {
    padding: 0 4px;

    @include media-breakpoint-up(xl) {
      &:not(.fa-caret-down) {
        padding: 0 3px 5px;
      }

      &.fa-caret-down {
        position: absolute;
        bottom: 5px;
        transform: translateX(-50%);
      }
    }
  }

  span {
    padding: 0 4px;

    @include media-breakpoint-up(xl) {
      padding: 0;
      display: block;
      overflow: hidden;
      width: 100%;
    }
  }

  a.active, div.active {

    > span {
      @include media-breakpoint-down(lg) {
        font-weight: bold;
      }
    }

    @include media-breakpoint-up(xl) {
      background: $ci-color-12;
      color: $ci-color-1;
    }
  }

  &:hover {
    & > a, & > div {
      @include media-breakpoint-up(xl) {
        background: $ci-color-13;
        color: #2c4557;
      }
    }
  }
}

nav.menu ul li ul {
  display: none;

  @include media-breakpoint-up(xl) {
    position: absolute;
    width: 100%;
    left: 0;
    margin-top: 17px;
    box-shadow: 0px 3px 4px #e8e8e8;
    display: block;
    visibility: hidden;
    opacity: 0;
  }
}

nav.menu ul li ul li {
  @include media-breakpoint-up(xl) {
    white-space: normal;
    width: 100%;
    border-top: 1px solid #e8e8e8;

    a {
      background: $ci-color-13;
      color: $ci-color-1;
    }

    &:hover {
      a {
        background: $ci-color-1;
        color: $ci-color-13;
      }
    }

    span {
      word-break: break-word;
    }
  }
}

nav.menu ul li:hover ul {
  transition-duration: .2s;

  @include media-breakpoint-up(xl) {
    opacity: 1;
    visibility: visible;
    // display: block;
  }
}

nav.menu ul li div {
  @include media-breakpoint-down(lg) {
    &.open-sub-menu {
      ul {
        display: block;
      }
    }
  }
}
