:root {
  --color-mekaniq: #054e72;
  --ci-color-1: var(--color-mekaniq);
  --ci-color-2: var(--color-mekaniq);
  --ci-color-3: #c8c8c8;
  --ci-color-4: #f9f9f9;
  --ci-color-5: var(--color-mekaniq);
  --ci-color-6: #e0e0e0;
  --ci-color-7: #999999;
  --ci-color-8: var(--color-mekaniq);
  --ci-color-9: #efefef;
  --ci-color-10: #cccccc;
  --ci-color-11: #3c3c3c;
  --ci-color-12: #ebebeb;
  --ci-color-13: #fff;

  --ci-color1-darken10: #054064;
  --ci-color1-darken20: #05375b;
  --ci-color1-darken30: #05375b;
  --ci-color1-lighten10: #055b80;
  --ci-color1-lighten20: #05668e;
  --ci-color10-lighten15: #f2f2f2;

}

// Base Colors
$color-white: #ffffff;
$color-black: #000000;
$color-grey-dark: #666;
$color-grey: #ccc;
$color-grey-light: #f5f5f5;

$color-ifms: #95c11f;
$color-mekaniq: var(--color-mekaniq);

// CI Colors

$ci-color-1: var(--color-mekaniq);
$ci-color-2: var(--ci-color-2);
$ci-color-3: var(--ci-color-3);
$ci-color-4: var(--ci-color-4);
$ci-color-5: var(--ci-color-5);
$ci-color-6: var(--ci-color-6);
$ci-color-7: var(--ci-color-7);
$ci-color-8: var(--ci-color-8);
$ci-color-9: var(--ci-color-9);
$ci-color-10: var(--ci-color-10);
$ci-color-11: var(--ci-color-11);
$ci-color-12: var(--ci-color-12);
$ci-color-13: var(--ci-color-13);

$ci-color1-darken10: var(--ci-color1-darken10);
$ci-color1-darken20: var(--ci-color1-darken20);
$ci-color1-darken30: var(--ci-color1-darken30);

$ci-color1-lighten10: var(--ci-color1-lighten10);
$ci-color1-lighten20: var(--ci-color1-lighten20);
$ci-color10-lighten15: var(--ci-color10-lighten15);


/*$ci-color-1: $color-mekaniq;
$ci-color-2: #333333;
$ci-color-3: #c8c8c8;
$ci-color-4: #f9f9f9;
$ci-color-5: $color-mekaniq;
$ci-color-6: #e0e0e0;
$ci-color-7: #999999;
$ci-color-8: $color-mekaniq;
$ci-color-9: #efefef;
$ci-color-10: #cccccc;
$ci-color-11: #3c3c3c;
 */

$disabled-background-color: #eeeeee;

$color-error: #ef5350;
$color-success: #00e676;
$color-highlight: #64b5f6;

// UI Elements //

// Button
$nx-btn-background: $ci-color-1;
$nx-btn-font: $color-white;
$nx-btn-border: $ci-color-1;
$nx-btn-hover-background: $color-white;
$nx-btn-hover-font: $ci-color-1;
$nx-btn-hover-border: $ci-color-1;

// Icon Button
$nx-icon-btn-height: 32px;
$nx-icon-btn-width: 32px;
$nx-icon-btn-border: $ci-color-9;
$nx-icon-btn-background: $color-white;
$nx-icon-btn-font: $ci-color-1;
$nx-icon-hover-background: $ci-color-8;
$nx-icon-hover-font: $color-white;

// Form
$nx-input-focus-shadow: $color-highlight;

// Body
$nx-body-background: #fafafa;

// Footer
$nx-footer-background: #ebebeb;
$nx-footer-color: #999999;

// Table
$nx-table-odd-background: $color-white;
$nx-table-even-background: $ci-color-4;
$nx-table-hover: #e3f2fd;
$nx-table-header-font: $ci-color-7;
$nx-table-header-border: $ci-color-5;
