.close {
  font-size: 32px;

  &:hover {
    cursor: pointer;
  }
}

.vehicle-parts-container {
  padding: 8px;
  min-width: 320px;

  .focus {
    border-radius: 3px;
    border: 1px solid rgba(0,0,0,.03);
    box-shadow: 0 2px 2px rgba(0,0,0,.24), 0 0 2px rgba(0,0,0,.12);
  }

  img {
    display: block;
    margin: 0 auto;
  }

  .axle-control-button {
    float: right;
    font-size: 24px;
    margin: 4px;

    &:hover {
      cursor: pointer;
    }
  }

  .vehicle-part {
    position: relative;

    .focus:hover {
      border-radius: 3px;
      border: 1px solid rgba(0,0,0,.03);
      box-shadow: 0 2px 2px rgba(0,0,0,.24), 0 0 2px rgba(0,0,0,.12);
    }

    &:not(:last-child) {
      margin-bottom: 4px;
    }

    .vehicle-part-controls {
      position: absolute;
      top: 86px;

      i {
        font-size: 24px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .draggable-vehicle-part {
    &:hover {
      cursor: pointer;
    }
  }
}
