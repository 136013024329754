.calibration-modal {
  .modal-content {
    @include media-breakpoint-up(md) {
      max-height: 85vh;
    }

  }

  .modal-body {
    @include media-breakpoint-up(md) {
      max-height: calc(85vh - 136px);
    }
  }
}
