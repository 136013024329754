.ifms-header-content {
  width:100%;
  position: fixed;
  top: 0px;
  z-index: 1000;
}

.ifms-main-content {
  min-height: calc(100vh - 40px);
  padding-top: 80px;

  @include media-breakpoint-up(lg) {
    padding-top: 100px;
  }

  @include media-breakpoint-up(xl) {
    padding-top: 120px;
    min-height: calc(100vh - 40px); //40px = footer height
  }
}

.ifms-container {
  background-color: $color-white;
  padding: 30px 15px;
}

@media (max-width: 576px) {

  .ifms-container {
    padding: 15px;
  }

}

.pi-check {
  background: var(--ci-color-1);
  color: #FFFFFF;
}
