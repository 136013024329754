.mandator-logo__modal {
  .modal-content {
    width: 500px;
    margin: 0 auto;
  }
}

.mandator__logo_container {
  position: relative;
  display: block;
  width: 250px;
  height: 140.62px;
  margin-bottom: 20px;
}

.mandator__logo_container:hover {
  .mandator__logo_container__buttons_container {
    button {
      display: block;
      opacity: 1;
    }
  }

  .mandator__logo_container__message_overlay {
    display: block;
    opacity: 1;
  }
}

.mandator__logo_container__brand_image {
  width: 100%;
  height: 100%;
}

.mandator__logo_container__buttons_container {
  position: absolute;
  display: flex;
  width: 100%;
  bottom: 0;
  left: 0;

  button {
    min-width: 100px !important;
  }

  button {
    display: none;
    opacity: 0;
    transition: opacity .25s ease;
  }
}

.mandator__logo_container__edit {
  width: 50% !important;
}

.mandator__logo_container__edit_100p {
  width: 100% !important;
}

.mandator__logo_container__delete {
  width: 50% !important;
}

.mandator__logo_container__message_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 5px;
  background: #efefefe3;
  color: $ci-color-1;

  display: none;
  opacity: 0;
  transition: opacity .25s;
}

.mandator__delete-modal__info-text {
  margin-bottom: 20px;
  color: red;
  font-weight: bold;
  font-size: 22px;
}

.mandator__delete-modal__mandator-child-list {
  font-size: 18px;

  ul {
    padding-top: 10px;
  }

  a:hover {
    text-decoration: none;
  }

  li {
    list-style: none;
  }
}
