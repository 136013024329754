.ui-paginator {
    background: none;
    border: none;
    margin-top: 30px;

    .ui-paginator-page, .ui-paginator-next, .ui-paginator-last, .ui-paginator-first, .ui-paginator-prev {
        background: unset;
        border: none;
        color: #ccc;
    }

    .ui-paginator-page.ui-state-active {
        background: unset;
        color: var(--ci-color-1);
    }


}
