@import 'component';

@import 'configurator/all';

@import 'parts/tire';
@import 'parts/axle';

@import 'led-state';
@import 'monitoring';

.route-map {
  min-height: 300px;
}

.vehicle-map {
  min-height: 300px;
  max-height: 300px;
}
