.ifms-vehicle-calibration-axles-trailer {
  margin-top: 30px
}

.imfs-vehicle-calibration-axles-seperator {
  margin-top: 30px;
  border-bottom: 1px solid $color-mekaniq;
}

.calibration-modal-footer {
  justify-content: flex-end !important;
  width: 100%;
}
