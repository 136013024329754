.filters {
    width: 100%;
    background-color: $color-white;
    border-top: $colored-border-thickness solid $ci-color-5;
    border-bottom: $colored-border-thickness solid $ci-color-5;
    padding: 30px 15px;

    .filter {
        margin-bottom: 20px;

        .filter-label {
            color: $ci-color-7;
            font-weight: 400;
            margin-bottom: 3px;
        }

        .filter-element {
            position: relative;

            .reset-filter {
                position: absolute;
                right: 0;
                top: -22px;
                color: #666;
                font-size: 12px;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .deselectable-filters {
        margin-bottom: 15px;

        .deselectable-filter {
            float: left;
            margin-right: 5px;
            margin-bottom: 5px;
            background-color: $ci-color-6;
            padding: 1px 20px 1px 7px;
            @include border-radius(15px);
            font-size: 12px;
            position: relative;

            &.without-icon {
                padding-right: 7px;
                background-color: $ci-color-9;
                color: $color-grey-dark;
            }

            .remove-filter {
                position: absolute;
                right: 6px;
                top: 1px;
                color: $color-grey-dark;
                @include transition(all 0.2s ease-in-out);

                &:hover {
                    cursor: pointer;
                    color: $color-error;
                }
            }
        }
    }

    .nx-btn-non-cta {
        width: 100%;
    }
}
