body{
    background-color: $nx-body-background;
}

.password-reset {

    .password-reset-box {
        width: 400px;
        height: 180px;
        position: absolute;
        top: 50%;
        margin-top: -300px;
        left: 50%;
        margin-left: -200px;
        border: 1px solid $ci-color-6;

        .header {
            background-color: var(--ci-color-1);
            height: 35px;
            line-height: 35px;
            padding-left: 20px;
            font-size: 20px;
            color: $color-white;
            text-transform: uppercase;
            font-weight: 400;
        }

        .content {
            height: 140px;
            background-color: $color-white;
            border-top: 1px solid $ci-color-5;
            padding: 10px 20px 20px 20px;

            .form-input {
                margin-bottom: 15px;

                label {
                    font-weight: 400;
                }
            }
        }

        .nx-btn {
            float: right;
            display: block;
        }

    }

}

// Media queries
@media (max-width: 991px) {
    .password-reset {

        .password-reset-box {
            position: relative;
            width: 100%;
            top: unset;
            left: unset;
            margin-top: unset;
            margin-left: unset;
            border: none;
            border-bottom: 1px solid $ci-color-6;
        }

    }
}
