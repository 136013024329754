// toggle menu on mobile
.ifms-header-content {
    .menu-container {
      z-index: 1;

        @include media-breakpoint-down(lg) {
            display: none;

            &.menu-open {
                display: block;
            }
        }
    }

}

.ifms-header-content {
  position: fixed;
  background: $ci-color-1;
  z-index: 1000;
  // height: 60px;
  width: 100%;
  top: 0;
  left: 0;

  .sub-header {
    background: $ci-color-12;
    height: 20px;
    width: 100%;

    span {
      display: block;
      text-align: right;
      width: 100%;
    }

    .powered-by-tyrewatch-text {
      span {
        text-align: center;
        color: black;
      }
    }

    .tymacom-text {
      span {
        text-align: left;
        color: black;
      }
    }
  }

  .mobile-menu-button {
    height: 60px;

    button {
      position: absolute;
      right: 15px;
      top: 12px;
      width: 40px;
      height: 40px;
      font-size: 22px;
      background: none;
      border: none;
      color: white;

      @include media-breakpoint-between(md, lg) {
        position: fixed;
      }

    }

    @include  media-breakpoint-up(xl) {
      display: none;
    }
  }

  img#logo {
    position: absolute;
    height: 50px;
    top: 6px;

    @include media-breakpoint-up(lg) {
      height: 70px;
      top: 6px;
    }

    @include media-breakpoint-up(xl) {
      height: 90px;
      top: 4px;
    }
  }

  .powered-by {
    @include media-breakpoint-down(xl) {
      display: none;
    }
    img {
      height: 58px;
      margin: 10px 0 0 auto;
      display: block;
    }
  }
}
