.panel {
    position: relative;
    width: 100%;

    .panel-title {
        color: $ci-color-1;
        font-weight: 400;
        font-size: 14px;
        margin: 6px 0;
        text-transform: uppercase;
    }

    .panel-content {
        width: 100%;
        min-height: 200px;
        background-color: $color-white;
        border-top: $colored-border-thickness solid $ci-color-5;
        border-bottom: $colored-border-thickness solid $ci-color-5;
        padding: 30px 15px;
    }

}
