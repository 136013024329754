@font-face {
    font-family: 'icomoon';
    src:  url('fonts/icomoon.eot?rx6z7m');
    src:  url('fonts/icomoon.eot?rx6z7m#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?rx6z7m') format('truetype'),
    url('fonts/icomoon.woff?rx6z7m') format('woff'),
    url('fonts/icomoon.svg?rx6z7m#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    text-rendering: auto;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-trailer:before {
    content: "\e903";
  }
  .icon-van:before {
    content: "\e900";
  }
  .icon-truck-trailer:before {
    content: "\e901";
  }
  .icon-truck:before {
    content: "\e902";
  }
  .icon-tractor:before {
    content: "\e904";
  }
  .icon-otr-scraper:before {
    content: "\e905";
  }
  .icon-otr-loader:before {
    content: "\e906";
  }
  .icon-otr-dumper:before {
    content: "\e907";
  }
  .icon-off-road:before {
    content: "\e908";
  }
  .icon-loader:before {
    content: "\e909";
  }
  .icon-fire:before {
    content: "\e90a";
  }
  .icon-doc-tug:before {
    content: "\e90b";
  }
  .icon-doc-crane:before {
    content: "\e90c";
  }
  .icon-digger:before {
    content: "\e90d";
  }
  .icon-coach:before {
    content: "\e90e";
  }
  .icon-bus:before {
    content: "\e90f";
  }
  .icon-truck-front:before {
    content: "\e910";
  }
  .icon-simple-tyre:before {
    content: "\e911";
  }
  .icon-speed-slow:before {
    content: "\e912";
  }
  .icon-meter:before {
    content: "\e913";
  }
  .icon-meter-fast:before {
    content: "\e914";
  }
  .icon-meter-medium:before {
    content: "\e915";
  }
  .icon-pencil:before {
    content: "\e916";
  }
  .icon-meter-slow:before {
    content: "\e917";
  }
  .icon-meter3:before {
    content: "\e918";
  }
  .icon-meter4:before {
    content: "\e919";
  }
  .icon-adjust:before {
    content: "\e91a";
  }
  .icon-steering-wheel:before {
    content: "\e91b";
  }
  .icon-gas:before {
    content: "\e91c";
  }
  .icon-timer:before {
    content: "\e91d";
  }
  .icon-engine:before {
    content: "\e91e";
  }
  .icon-oil-pressure:before {
    content: "\e91f";
  }
  .icon-coolant-temperature:before {
    content: "\e920";
  }
  .icon-tools:before {
    content: "\e921";
  }
  .icon-question-circle-o:before {
    content: "\e922";
  }
  .icon-scale:before {
    content: "\e923";
  }
  .icon-thermometer-2:before {
    content: "\e924";
  }
  .icon-stopwatch:before {
    content: "\e925";
  }
  .icon-user-tie:before {
    content: "\e976";
  }
  .icon-meter2:before {
    content: "\e9a7";
  }
  .icon-leak-rate:before {
    content: "\10ffff";
  }
  .icon-wheel-loss:before {
    content: "\e926";
  }
  