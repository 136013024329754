.ifms-sensor-placeholder {
  margin: 4px;
  border-radius: 3px;
  border: 1px solid rgba(0,0,0,.03);
  box-shadow: 0 2px 2px rgba(0,0,0,.24), 0 0 2px rgba(0,0,0,.12);

  &:hover:not([disabled]) {
    cursor: pointer;
    background-color: transparent;
    background-color: rgba(0, 0, 0, 0.03);
  }

}
