@import "../../../src/styles/modules/colors";

.search-filters-checkbox {

  label {
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    border-bottom: 1px solid $color-mekaniq;

    i {
      color: green;

      &.fa-times {
        color: red;
      }
    }
  }

  input[type="checkbox"] {

    visibility: hidden;
    width: 0px;
  }
}
