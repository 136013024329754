.calendar-widget {
    position: relative;
    width: 126px;

    &.invalid {
        .ui-inputtext {
            border-bottom-color: $color-error !important;
        }
    }

    i.fa-caret-down {
        position: absolute;
        right: 21px;
        top: 6px;
        font-size: 18px;
    }

    .ui-button {
        border: 1px solid $ci-color-1;
        background-color: $ci-color-1;

        &:hover:enabled {
            color: $ci-color-1;
            border: 1px solid $ci-color-1;
            background-color: white;
        }

        &:focus {
            background-color: $ci-color-1;
        }
    }
}
