.fleet-unit-vehicle {
  max-width: 260px;
  // margin-top: 38px;
}

@media screen and (-ms-high-contrast:active), (-ms-high-contrast:none) {
  .fleet-unit-vehicle {
    .axle-frame {
      margin: -40px 0;
    }
  }
}

.fleet-unit-notification-icon {
  width: 20px;
  height: 20px;
}

.ifms-icon-badge {
  position: relative;
  margin-right: 6px;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.24);
}

.ifms-icon-badge[data-badge]:after {
  content: attr(data-badge);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: -8px;
  right: -6px;
  font-weight: 600;
  font-size: 12px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #ff4081;
  color: #fff;
}

.ifms-icon-badge.ifms-okay[data-badge]:after {
  background: #00695c;
}

.ifms-icon-badge.ifms-warning[data-badge]:after {
  background: #f9a825;
}

.ifms-icon-badge.ifms-danger[data-badge]:after {
  background: #c62828;
}

.ifms-icon-badge.ifms-grey[data-badge]:after {
  background: #aaaaaa;
}

.ifms-icon-badge.ifms-black[data-badge]:after {
  background: #050407;
}

.ifms-icon-badge.ifms-blue[data-badge]:after {
  background: #417ab7;
}

$tooltip-content-width: 196px;

.ifms-icon-badge-tooltip {
  position: relative;
  display: inline-block;

  .tooltip-content {
    min-width: $tooltip-content-width;

    display: none;
    background-color: #fff;
    color: #292b2c;

    border-radius: 3px;
    border: 1px solid rgba(0,0,0,.03);
    box-shadow: 0 2px 2px rgba(0,0,0,.24), 0 0 2px rgba(0,0,0,.12);

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }

  &:hover .tooltip-content {
    display: block;
  }
}

@media (max-width: 447px) {
  .ifms-icon-badge-tooltip {
    &:hover .tooltip-content {
      left: -$tooltip-content-width;
    }
  }
}
