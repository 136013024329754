body{
    background-color: $nx-body-background;
}

.login {

    .login-box {
        width: 400px;
        // height: 277px;
        position: absolute;
        top: 50%;
        margin-top: -300px;
        left: 50%;
        margin-left: -200px;
        border: 1px solid $ci-color-6;

        .header {
            background-color: $ci-color-1;
            height: 35px;
            padding-top: 8px;
            padding-left: 20px;

            .logo {
                width: $logo-width;
                color: $color-white;
            }
        }

        .content {
            // height: 240px;
            background-color: $color-white;
            border-top: 1px solid $ci-color-5;
            padding: 10px 20px 20px 20px;

            .form-input {
                margin-bottom: 15px;

                label {
                    font-weight: 400;
                }
            }
        }

        .nx-btn {
            float: right;
            display: block;
        }

        .remember-me, .forgot-password {
            font-size: 12px;
        }

        .remember-me {
            line-height: 39px;
        }

        .forgot-password {
            text-align: right;
            text-decoration: underline;
            color: $color-grey-dark;

            &:hover {
                cursor: pointer;
            }
        }

    }

}

// Media queries
@media (max-width: 991px) {
    .login {

        .login-box {
            position: relative;
            width: 100%;
            top: unset;
            left: unset;
            margin-top: unset;
            margin-left: unset;
            border: none;
            border-bottom: 1px solid $ci-color-6;
        }

    }
}
