.ifms-fleet-notifications {
    &.simple-notification {
        .sn-content {
            max-width: calc(100% - 70px);
        }

        .sn-title {
            font-weight: bold;
            font-size: 24px;
        }
    }
}
