.ifms-monitoring-entry {

  margin: 4px;

  .ifms-monitoring-entry-image {
    width: 24px;
    margin: auto;
  }

  .ifms-monitoring-entry-label {
    font-weight: bold;
    text-align: center;
  }

  .ifms-monitoring-entry-value {
    text-align: center;
  }

  border: 1px solid rgba(0,0,0,.03);

  &:hover {
    cursor: pointer;

    border-radius: 3px;
    border: 1px solid rgba(0,0,0,.03);
    box-shadow: 0 2px 2px rgba(0,0,0,.24), 0 0 2px rgba(0,0,0,.12);

    background-color: transparent;
    background-color: rgba(0, 0, 0, 0.03);
  }

  &.selected {
    background-color: $nx-btn-background;
    color: $nx-btn-font;

    &:hover {
      background-color: $nx-btn-hover-background;
      color: $nx-btn-hover-font;
    }
  }
}
