@include media-breakpoint-up(lg) {
  .table-responsive {
    display: table;
  }
}

.bg-white {
  background-color: white;
}

.bg-blue {
  &.normal {
    background-color: rgba(217, 237, 247, 1);
  }
  &.brighter {
    background-color: rgba(217, 237, 247, 0.5);
  }
  &.bright {
    background-color: rgba(217, 237, 247, 0.25)
  }
}
