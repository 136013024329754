.user-check {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(-50%, -50%);
}

.password_requirements {
  white-space: pre-wrap;
}
