// @import 'common';

.vehicle-part-tire {
  .vehicle-part-label {
    padding-left: 10px;
  }

  .two-column-input {
    margin-bottom: 6px;

    label {
      line-height: 18px;
    }
  }
}

.notification-container {
  margin: 12px 0;
  margin-bottom: 18px;
  font-size: 18px;
  padding: 0.25rem 0.75rem;

  label, span {
    @extend .alert-danger
  }
}
