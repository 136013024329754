@import "common";

h4.articles-title {
  margin: 30px 0 15px 0;
  font-size: 14px;
  color: $ci-color-5;
  text-transform: uppercase;
}

.date-inputfield {
  width: 100% !important;
}

.reset-btn {
  float: right;
  width: 100px;
  min-width: unset;
}

table.order-info-table {
  float: right;
  width: 80%;

  tbody {
    tr {
      td {
        &:first-child {
          font-weight: 500;
        }

        &:last-child {
          text-align: right;
        }
      }
    }
  }
}

.table-total-amount {
  width: 100%;
  text-align: right;
  border-top: 1px solid $ci-color-10;
  padding: 10px 0;
  margin-top: 5px;

  span {
    font-weight: 500;
    margin-right: 10px;
  }
}

.reference-box {
  border: 1px solid $ci-color-9;
  padding: 5px;
  margin: 5px 0;

  .title {
    font-size: 12px;
    font-weight: bold;
  }

  .body {

  }
}

@media (min-width: 1200px) {
  .date-inputfield {
    width: auto !important;
  }
}

@media (max-width: 1200px) {

  .input-row {
    margin-bottom: 10px;
  }

}
