.sentyre-ci {
  --color-mekaniq: rgba(0, 137, 192, 1);
  --ci-color-1: var(--color-mekaniq);
  --ci-color-2: #5f696c;
  --ci-color-3: #c8c8c8;
  --ci-color-4: #f9f9f9;
  --ci-color-5: var(--color-mekaniq);
  --ci-color-6: #e0e0e0;
  --ci-color-7: #999999;
  --ci-color-8: var(--color-mekaniq);
  --ci-color-9: #efefef;
  --ci-color-10: #cccccc;
  --ci-color-11: #3c3c3c;
  --ci-color-12: #ebebeb;

  --ci-color1-darken10: #0082b9;
  --ci-color1-darken20: #007db3;
  --ci-color1-darken30: #0078ad;
  --ci-color1-lighten10: #008ec6;
  --ci-color1-lighten20: #0094cd;
}

.sentyre-ci {
  .ifms-header-content {
    background: $ci-color-13;

    #logo {
      height: 40px;
      top: 9px;

      @include media-breakpoint-up(xl) {
        height: 55px;
      }
    }

    .mobile-menu-button button {
      color: $ci-color-1;
    }

    nav.menu {
      @include media-breakpoint-between(md, lg) {
        background: $ci-color-13;
      }

      border-color: $ci-color-1;
    }

    nav.menu > ul > li > a,
    nav.menu > ul > li > div {
      background: $ci-color-13;
      color: $ci-color-1;

      @include media-breakpoint-up(xl) {
        i.fa-caret-down {
          color: $ci-color-13;
          bottom: -9px;
        }

        &.active {
          background: $ci-color-12;

          i.fa-caret-down {
            color: $ci-color-12;
          }
        }
      }
    }

    nav.menu > ul > li:hover > a,
    nav.menu > ul > li:hover > div,
    nav.menu > ul > li > div li:hover a {
      @include media-breakpoint-up(xl) {
        background: $ci-color-1;
        color: $ci-color-13;
      }
    }

    nav.menu > ul > li:not(:last-child) {
      border-color: $ci-color-12;
    }

    nav.menu > ul > li > div a {
      background: $ci-color-13;
      color: $ci-color-1;

      @include media-breakpoint-up(xl) {
        &.active {
          background: $ci-color-12;
        }
      }
    }

    .sub-header {
      background: $ci-color-1;
      color: $ci-color-13;

      span {
        text-align: left;
      }
    }
  }
}
