.admin-settings {

    .settings-header {
        position: relative;
        background-color: $color-white;
        border-bottom: 1px solid $ci-color-6;
        padding-top: 30px;
    }

}

.tabs-panel ul.tabs-panel-items {
    border-bottom: 0 !important;
}

.tab {
    padding: 20px 0;
}

.ui-selectbutton .ui-button.ui-state-active {
    background-color: $ci-color-1;
    border-color: $ci-color-1;
}


// Media queries
@media (max-width: 991px) {
    .admin-settings {
        .tabs-panel {
            margin-top: 5px;

            ul {
                li {
                    width: 50%;
                }
            }
        }
    }
}
