.custom-checkbox {
    width: 100%;

    label {
        width: 100% !important;
        margin-bottom: 3px;

        .label{
            margin-right: 20px;
            font-size: 14px;
            padding-top: 4px;
            color: $ci-color-6;
            font-weight: normal;
        }
    }

    input[type=checkbox] {
        opacity: 0;
        position: absolute;
        z-index: 12;
        width: 19px;
        height: 19px;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
    }

    input[type=checkbox]:checked, input[type=checkbox]:focus {
        outline: none !important;
    }

    input[type=checkbox]+.lbl {
        position: relative;
        z-index: 11;
        display: inline-block;
        margin: 0;
        line-height: 20px;
        min-height: 14px;
        min-width: 14px;
        font-weight: normal;
        font-size: 14px;
        color: $ci-color-7;
        @include transition(all 0.2s ease-in-out);
    }

    input[type=checkbox]+.lbl::before {
        font-family: 'FontAwesome' !important;
        color: $ci-color-3;
        content: "\f096";
        font-size: 18px;
        border-radius: 0;
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        height: 13px;
        line-height: 13px;
        min-width: 13px;
        margin-right: 8px;
        margin-top: -2px;
        @include transition(all 0.2s ease-in-out);
    }

    /* Checked state styles */
    input[type=checkbox]:checked+.lbl::before {
        display: inline-block;
        font-family: 'FontAwesome' !important;
        content: "\f14a";
        color: $ci-color-1;
    }

    /* Hover state styles */
    input[type=checkbox]:hover+.lbl::before, input[type=checkbox]+.lbl:hover::before {
        border-color: #1c7aad;
    }


    /* Disabled state styles */
    input[type=checkbox]:disabled+.lbl::before, input[type=checkbox][disabled]+.lbl::before, input[type=checkbox].disabled+.lbl::before {
        background-color: #DDD !important;
        border-color: #CCC !important;
        color: #BBB;
    }

    /* Styles for checkbox */
    input[type=checkbox]+.lbl::before {
        font-family: Arial,Helvetica,sans-serif;
        font-size: 18px;
    }

    input[type=checkbox]:checked+.lbl::before {
        font-family: 'FontAwesome' !important;
        content: "\f14a";
        color: $ci-color-1;
    }

    input[type=checkbox]:checked+.lbl {
        color: $ci-color-1;
    }

    span {
        text-align: right;
        vertical-align: middle;
        font-size: 12px;
        color: #454545;
    }

    input[type=checkbox], span {
        &:hover {
            cursor: pointer;
        }
    }
}
