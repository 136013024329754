.image-cropper {
  input[type="file"] {
    display: none;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-cropper__choose-file {
  @extend .nx-btn;
  text-align: center;
}

.image-cropper__image-to-crop-container {
  margin: 0 auto 20px;
  width: 100%;
  height: 245.24px;
}

.image-cropper__preview {
  background: #cccccc;
  margin: 0 auto 20px ;
  width: 100% !important;
  height: 245.24px;
  overflow: hidden;
}

.image-cropper__upload-button {
  @extend .nx-btn;
}

.image-cropper-error-messages__message {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  color: red;
}
