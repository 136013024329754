.col.header {
  font-size: 14px;
  font-weight: bold;
}

.col.ifms-axle {
  max-width: 320px;
}

.ifms-axle-mt {
  margin-top: 50px
}

label.treshold {
  color: $color-mekaniq;
}

.treshold {
  input {
    border: 1px solid #e0e0e0;
    border-bottom: 2px solid $color-mekaniq;
    background-color: #fff;
    height: 39px;
    font-size: 14px;
    width: 100%;
    padding: 0 10px;
    box-shadow: none;
    transition: all .3s ease-in-out;

    &:active, &:focus {
      outline: 0;
      box-shadow: 0 0 5px $color-highlight;
      border: 1px solid $color-highlight;
    }

    &.disabled {
      background: $disabled-background-color;
    }
  }

  /* For Firefox */
  input[type=number] {
    -moz-appearance:textfield;
  }

  /* Webkit browsers like Safari and Chrome */
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
