.version-check-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 1000;
}

.version-check-banner__container {
  margin-top: 10px;
  background: $ci-color-1;
  color: $ci-color-13;
  padding: 10px;
  font-size: 18px;

  a {
    font-weight: bold;
    color: $ci-color-13;
  }
}
