.ifms-mailing-list {

  padding: 0px;

  li {
    list-style: none;
    padding: 5px 0;
  }

  .ifms-button-icon {
    &:hover {
      cursor: pointer;
    }
  }
}
