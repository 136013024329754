.quicksearch-panel {
    min-height: 70px;
    padding: 15px 0;

    .nx-btn {
        float: right;

    }

    .ui-multiselect-items-wrapper {
        max-height: 400px;
    }

    .reset-btn {
        float: right;
        width: 100px;
        min-width: unset;
    }

    .nx-btn-flex {
        width: 150px;
        margin-right: 10px;
        min-width: unset;
    }
}

// Media queries
@media (max-width: 991px) {

    .quicksearch-panel {
        line-height: normal !important;

        .input-row {
            margin-bottom: 10px;
        }

        button {
            width: 100%;
        }
    }

}
