input.ngb-typeahead {
    border-radius: 0;

    &:focus {
        box-shadow: none;
    }
}

ngb-typeahead-window.dropdown-menu {
    width: calc(100% - 30px);
    padding: 0;
}

.dropdown-menu .dropdown-item.active, .dropdown-menu .dropdown-item:active {
    background: $ci-color-1;
}
