$normal-font-size: 36px;
$normal-color: var(ci-color-1);

$alert-font-size: 42px;
$alert-color: #c62828;

$no-status-color: #999999;

$animation-duration: 2s;
$ifms-box-duration: 1.5s;

.ifms-led {
  margin-bottom: 18px;

  .icon-wheel-loss {
    font-size: 36px;
    color: $ci-color-1;

    &.lost {
      color:  $alert-color;
    }
  }

  .ifms-box-online-state {
    font-size: 20px;

    &.ifms-box-online {
      color: var(ci-color-1);

      .fa-inbox {
        color: var(ci-color-1);
      }
    }

    &.ifms-box-offline {
      color: #999999;
    }

    .ifms-box-icon {
      text-align: center;

      .fa.fa-wifi {
        font-size: 14px;
        display: block;
        margin-bottom: -10px;
      }

      .fa.fa-inbox {
        font-size: 15px;
      }

      p {
        font-size: 12px;
        margin-top: -8px;
      }

    }

    .wifi-flashing {
      -webkit-animation: wifi-frames $ifms-box-duration cubic-bezier(1,0,0,1) infinite;
      animation: wifi-frames $ifms-box-duration cubic-bezier(1,0,0,1) infinite;
    }

    @-webkit-keyframes wifi-frames {
      0% { color: $no-status-color; }
      100% { color: var(ci-color-1); }
    }

    @keyframes wifi-frames {
      0% { color: $no-status-color; }
      100% { color: var(ci-color-1); }
    }
  }

  .ifms-led-state {
    font-size: $normal-font-size;
    line-height: $normal-font-size;
    margin-right: 9px;
    // width: 24px;

    .led-icon {
      // vertical-align: middle;
      // text-align: center;
    }

    &.led-danger {
      color: $alert-color;
    }

    &.led-flashing {
      -webkit-animation: led-warning $animation-duration cubic-bezier(1,0,0,1) infinite;
      animation: led-warning $animation-duration cubic-bezier(1,0,0,1) infinite;
    }

    @-webkit-keyframes led-warning {
      0% { color: $no-status-color; }
      100% { color: $alert-color; }
    }

    @keyframes led-warning {
      0% { color: $no-status-color; }
      100% { color: $alert-color; }
    }

    &.led-on {
      color: #00695c;
    }

    &.led-off {

    }

    &.led-unknown {
      // color: #efefef;
      color: #999999;
    }


  }

  .ifms-led-state-message {
    .title {
      font-weight: bold;
      line-height: 12px;
      padding-bottom: 13px;
    }

    .message {
      font-size: 12px;
    }
  }

}
