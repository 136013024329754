.searchable-dropdown {
    position: relative;
    width: 200px;

    &.disabled {
        .dropdown-input, .selected-item {
            border-bottom-color: $ci-color-10;
            color: $ci-color-10;
        }
    }

    .dropdown-input, .selected-item {
        position: relative;
        border: 1px solid $ci-color-10;
        border-bottom: $colored-border-thickness solid $ci-color-5;
        background-color: white;
        height: $form-element-height;
        line-height: $form-element-height;
        font-size: $form-element-fontsize;
        width: 100%;
        padding: 0 30px 0 10px;
        text-align: left;
        overflow: hidden;
        @include transition(all 0.30s ease-in-out);
    }

    .searchable-dropdown-menu {
        ul {
            position: absolute;
            top: $form-element-height;
            margin: 0;
            padding: 0;
            list-style-type: none;
            background-color: $ci-color-4;
            width: auto;
            border: 1px solid $ci-color-5;
            border-top: none;
            z-index: 99;
            max-height: 400px;
            overflow-y: auto;
            //overflow-x: hidden;

            li {
                font-size: 12px;
                padding: 5px 10px;
                @include transition(color 0.2s ease-in-out);
                float: none;
                min-width: 100px !important;
                width: unset !important;

                &:hover {
                    cursor: pointer;
                }

                &.focused {
                    background-color: $color-highlight !important;
                }

                &:focus, &:active {
                    outline: none;
                }
            }
        }

    }
}
