.header-title {
    padding: 10px 0;
}

.feature-info {
    background-color: #f6f6f6;
    height: 100px;
    text-align: center;
}

.loading-overlay {
    background-color: rgba(0,0,0,0.3);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;

    .message {
        z-index: 9999;
        background-color: $color-white;
        padding: 10px;
        width: 200px;
        height: 40px;
        position: absolute;
        top: 50%;
        margin-top: -100px;
        left: 50%;
        margin-left: -100px;
        text-align: center;
        border-bottom: $colored-border-thickness solid $ci-color-1;
    }
}
