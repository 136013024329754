.michelin-ifmscloud-ci {
    --color-mekaniq: #054e72;
    --ci-color-1: var(--color-mekaniq);
    --ci-color-2: var(--color-mekaniq);
    --ci-color-3: #c8c8c8;
    --ci-color-4: #f9f9f9;
    --ci-color-5: var(--color-mekaniq);
    --ci-color-6: #e0e0e0;
    --ci-color-7: #999999;
    --ci-color-8: var(--color-mekaniq);
    --ci-color-9: #efefef;
    --ci-color-10: #cccccc;
    --ci-color-11: #3c3c3c;
    --ci-color-12: #ebebeb;

    --ci-color1-darken10: #054064;
    --ci-color1-darken20: #05375b;
    --ci-color1-darken30: #05375b;
    --ci-color1-lighten10: #055b80;
    --ci-color1-lighten20: #05668e;
}

.michelin-ifmscloud-ci {
    .ifms-header-content {
        background: $ci-color-13;

        #logo {
            height: 40px;
            top: 9px;

            @include media-breakpoint-up(xl) {
                height: 55px;
            }
        }

        .mobile-menu-button button {
            color: $ci-color-1;
        }

        nav.menu {
            @include media-breakpoint-between(md, lg) {
                background: $ci-color-13;
            }

            border-color: $ci-color-1;
        }

        nav.menu > ul > li > a,
        nav.menu > ul > li > div {
            background: $ci-color-13;
            color: $ci-color-1;

            @include media-breakpoint-up(xl) {
                i.fa-caret-down {
                    color: $ci-color-1;
                }

                &.active {
                    background: $ci-color-12;

                    i.fa-caret-down {
                        color: $ci-color-12;
                    }
                }
            }
        }

        nav.menu > ul > li:hover > a,
        nav.menu > ul > li:hover > div,
        nav.menu > ul > li > div li:hover a {
            @include media-breakpoint-up(xl) {
                background: $ci-color-1;
                color: $ci-color-13;
            }
        }

        nav.menu > ul > li:not(:last-child) {
            border-color: $ci-color-12;
        }

        nav.menu > ul > li > div a {
            background: $ci-color-13;
            color: $ci-color-1;

            @include media-breakpoint-up(xl) {
                &.active {
                    background: $ci-color-12;
                }
            }
        }

        .sub-header {
            background: $ci-color-12;
            color: $ci-color-1;
        }
    }
}
