$header-topbar-height: 60px;
$header-topbar-height-mobile: 100px;

$footer-height: 40px;
$footer-gap: 30px;

$logo-width: 100%;

$form-element-height: 39px;
$form-element-fontsize: 14px;

$tab-width: 180px;
$tab-height: 40px;

$colored-border-thickness: 2px;
