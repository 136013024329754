@import '~bootstrap/scss/functions';
@import "~bootstrap/scss/mixins";
@import "variables";

.svg-icon svg {
  margin-bottom: 8px;
  margin-right: 8px;
  height: 24px;
}

.fleet-and-depot {
  font-size: 18px;
  margin-bottom: 15px;
  position: relative;

  .fleet-and-depot-container {
    padding: 10px 0 10px 0;
    border-bottom: 1px solid #e5e5e5;
    border-top: 1px solid #e5e5e5;
    position: relative;
    z-index: 15;
    font-size: 14px;
    background-color: #fafafa;
  }

  .text-overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}



.most-important-notification {
  position: relative;
  margin: 10px auto;
  color: white;

  .tyre-information {
    background-color: #525252;
    text-align: center;
    color: white;
    padding: 15px 5px;

    .tyre-information-title {
      font-size: 14px;
    }

    .tyre-information-details {
      font-size: 32px;
      font-weight: bold;
    }
  }

  .most-important-notification-details {
    position: relative;
    padding: 15px 5px 20px 10px;
    font-weight: bold;
    z-index: 15;
  }
}

.notification-badge-vehicle-ok {
  padding: 0 12px;
  position: absolute;
  right: -8px;
  bottom: -7px;
  z-index: 20;
  text-align: center;
  font-size: 12px;
  color: white;
  font-weight: bold;

  notification-badge-vehicle-ok-color {
    background-color: #525252 !important;
  }

  i {
    text-align: right;
    margin: 0 -6px 0 4px;
  }

  &:hover {
    cursor: pointer;
  }
}



.notification-badge-vehicle-ok-shadow {
  right: -4px;
  bottom: -1px;
  z-index: 10;
  background: black;
  width: 20px;
  height: 20px;
  position: absolute;
  transform: rotate(-53deg);
}

.notification-badge-vehicle-not-ok {
  padding: 0 12px;
  position: absolute;
  right: -8px;
  bottom: 3px;
  z-index: 20;
  text-align: center;
  font-size: 12px;
  color: white;
  font-weight: bold;

  i {
    text-align: right;
    margin: 0 -6px 0 4px;
  }

  &:hover {
    cursor: pointer;
  }
}

.notification-badge-vehicle-not-ok-shadow {
  right: -4px;
  bottom: 9px;
  z-index: -10;
  background: black;
  width: 20px;
  height: 20px;
  position: absolute;
  transform: rotate(-53deg);
}


.notification-badge-vehicle-not-ok-color {
  background-color: #525252 !important;
}

.fleet-btn {
  padding: 8px 24px;
  display: block;
  background: none;
  color: black;
  border: 1px solid black;
  text-transform: uppercase;
  transition-duration: 300ms;

  &.tile-btn {
    margin: 0 auto;
  }

  &:hover {
    outline: none;
    opacity: 0.7;
  }
}

.ui-dialog-titlebar {
  background: none;
  //padding-top: 0 !important;
  padding-bottom: 0 !important;
  max-height: 35px;
}

.ui-widget-overlay {
  background: rgba(0,0,0,0);
}

.ui-dialog-content {
  border-top: 1px solid black;
  //margin-top: -50px;
  background: #fafafa;

  h5 {
    margin-bottom: 15px;
  }
}

.notification-list {
  //border: 1px solid rgba(0, 0, 0, 0.1);

  li {
    list-style-type: none;
  }
}

.ui-dialog {
  box-shadow: 0px 0px 15px 0px #00000069;
}

.ui-corner-all {
  border-radius: 0px;
}

.popup-fake-header {
  margin: 10px 20px;

  i {
    display: block;
    margin: 0 0 0 auto;
    cursor: pointer;
  }
}

.popup-button-group {
  display: flex;
  justify-content: center;
  margin: 10px 20px;
  padding-top: 8px;

  .fleet-btn {
    margin: 5px 5px;
  }
}

.modal-body {

  .modal-custom-col-left {
    padding-right: 0;
  }

  .modal-custom-col-right {
    padding-left: 0;
  }

  @include media-breakpoint-up(xxl) {
    .col-lg-4 {
      .modal-custom-col-left {
        position: relative;
        flex: 0 0 40%;
        max-width: 40%;
        padding-right: 0;
      }

      .modal-custom-col-right {
        position: relative;
        flex: 0 0 60%;
        max-width: 60%;
        padding-left: 0;
      }

    }
  }
}


