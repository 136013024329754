.fleet-depot-vehicles {
    .fa-check {
        color: $color-success;
        font-size: 16px;
    }

    .fa-times {
        color: $color-error;
        font-size: 16px;
    }

    .set-default-address {
        width: auto;
        padding: 0 10px;
    }

    .vehicles-table {
        margin-top: 20px;

        .simple-table {

            thead {
                tr {
                    th {
                        padding: 0 10px;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        vertical-align: middle;
                        padding: 8px 10px;
                    }
                }
            }

        }
    }

}

// Media queries
@media (max-width: 991px) {
    .nx-small-btn {
        margin-bottom: 5px;
    }

}
