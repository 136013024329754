.simple-table {
    width: 100%;
    font-weight: 300;

    &.no-hover {
        tbody {
            tr {
                &:hover {
                    cursor: default;

                    &:nth-child(odd) {
                        background-color: $nx-table-odd-background;
                    }

                    &:nth-child(even) {
                        background-color: $nx-table-even-background;
                    }
                }
            }
        }
    }

    thead {
        tr {
            th {
                color: $nx-table-header-font;
                font-size: 14px;
                padding: 6px 10px;
                font-weight: 300;
            }
        }
    }

    tbody {
        tr {
            @include transition(all 0.2s ease-in-out);

            &:nth-child(odd) {
                background-color: $nx-table-odd-background;
            }

            &:nth-child(even) {
                background-color: $nx-table-even-background;
            }

            &:hover {
                cursor: pointer;
                background-color: $nx-table-hover;
            }

            td {
                vertical-align: middle;
                padding: 8px 10px;
            }
        }
    }
}

.mobile-sub-table {
    width: 150px;

    tr {
        background-color: transparent !important;

        td {
            width: 50%;
            background-color: transparent !important;
            padding: 0 !important;
        }
    }
}
