.hardware {

  .hardware-header {
    position: relative;
    background-color: #ffffff;
    border-bottom: 1px solid #e0e0e0;

    div.tab {
      display: none !important;
    }
  }

}
