.p-dialog-header .p-dialog-header-icons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.p-dialog-header .p-dialog-title ~ .p-dialog-header-icons {
  width: auto;
}

.p-dialog .p-dialog-header {
  border-bottom: none;
  padding: 1rem 1rem 0 1rem;
}


// paginator

.p-paginator button {
  color: $ci-color-1 !important;

  &.p-highlight {
    background: $ci-color-1 !important;
    border-color: $ci-color-1 !important;
    color: $ci-color-4 !important;
  }
}
