// TOGGLE SWITCH
.toggle-switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggle-switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $ci-color-10;
    -webkit-transition: .4s;
    transition: .4s;
}

.toggle-switch-slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 3px;
    background-color: $ci-color-4;
    -webkit-transition: .4s;
    transition: .4s;
}

.toggle-switch input:checked + .toggle-switch-slider {
    background-color: $ci-color-1;
}

.toggle-switch input:focus + .toggle-switch-slider {
    box-shadow: 0 0 1px $ci-color-1
}

.toggle-switch input:checked + .toggle-switch-slider:before {
    transform: translateX(14px);
}

/* Rounded sliders */
.toggle-switch-slider.round {
    border-radius: 34px;
}

.toggle-switch-slider.round:before {
    border-radius: 50%;
}
