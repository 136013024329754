.vehicle-wizard {

  .vehicle-wizard-header {
    position: relative;
    background-color: #ffffff;
    // border-bottom: 1px solid #e0e0e0;

    // div.tab {
    //   display: none;
    // }
  }

  .tabs-panel ul {
    // border-bottom: 0;
  }

}

.fleet-vehicle-wizard-header {

  .ifms-wizard-data-table {
    margin: 20px 0;

    tr {
      td {
        font-size: 14px;

        &:first-child {
          text-transform: capitalize;
          color: var(--ci-color-1);
          font-weight: 400;

          i {
            font-size: 30px;
            color: $ci-color-3;
          }
        }


        &:last-child {
          padding-left: 16px;
          // font-size: 18px;
        }
      }
    }
  }

}

.ifms-wizard-confirmation {

  .ui-dialog-footer {
    border: 0;
    padding: 0;
    margin: 0;
  }

}

.ifms-tpms-sensor-input {
  height: 54px;
}

@media (max-width: 375px) {
  .ifms-tpms-sensor-input {
    padding: 0 3px;
  }
}

.btn-primary {
  background-color: var(--ci-color-1);
  color: #ffffff;
  border-color: var(--ci-color-1);

  &:hover {
      background-color: #ffffff;
      color: var(--ci-color-1);
      border-color: var(--ci-color-1);
  }
}

.tpms-sensor-action-icon {
  margin-left: 6px;

  &:hover {
    cursor: pointer;
  }
}
