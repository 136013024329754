@import 'fleet-depot';

@import 'group-node';

@import 'vehicle-list';
@import 'vehicle-trips';

@import 'trip-history/all';

@import 'vehicles';
@import 'vehicle-wizard';

@import 'fleet-depot-tire-and-wheel-monitoring-report';
@import 'fleet-depot-vehicle-wizard/all';
@import 'fleet-depot-tree';

@import "tire-and-wheel-monitoring-report";

.vehicle-location-history-map {
  position: relative;
  height: 300px;
  min-height: 300px;
  max-height: 300px;

  .nx-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
    min-width: auto;
  }
}
