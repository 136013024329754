@import '~bootstrap/scss/functions';
@import "variables";
@import "~bootstrap/scss/mixins";

.ui-dialog {
    overflow-y: hidden;

    .ui-dialog-content {
        max-height: 75vh;
        overflow-y: auto;
    }
}


//***** TODO: Delete after testing the new modals ****/
.overview-dialog {
    .ui-dialog-content {

        max-height: 100vh;
        overflow: hidden !important;
        min-width: 50vw;

        @include media-breakpoint-up(lg) {
            max-height: 85vh;
        }

        @include media-breakpoint-up(xl) {
            max-height: 75vh;
        }

        .scroll-container {
            max-height: 59vh;
            overflow-y: auto;
        }

        .inner-container {
            padding: 5px 20px;

            h6 {
                margin-bottom: 15px;
            }

            i {
                text-align: center;
                min-width: 20px;
                margin-right: 10px;
            }
        }
    }
}
//***** TODO: END ****/


//****** Bootstrap Modal ******//
.modal-backdrop.show {
    opacity: 0.3;
}

.modal {
    //*** MODAL CONTAINERSIZE ***//
    .modal-dialog {
        width: 100%;
        //margin: 0;
        @include media-breakpoint-only(xs) {
            margin: 0;
        }

        @include media-breakpoint-up(sm) {
            max-width: calc(#{$sm-container} - 30px);
        }

        @include media-breakpoint-up(md) {
            max-width: calc(#{$md-container} - 30px);
        }

        @include media-breakpoint-up(lg) {
            max-width: calc(#{$lg-container} - 30px);
        }

        @include media-breakpoint-up(xl) {
            max-width: calc(#{$xl-container} - 30px);
        }

        @include media-breakpoint-up(xxl) {
            max-width: calc(#{$xxl-container} - 30px);
        }
    }

    .modal-content {
        max-height: 90vh;
        overflow: hidden;
        border-radius: 0px;

        @include media-breakpoint-up(md) {
            max-height: 70vh;
        }

        @include media-breakpoint-up(xxl) {
            margin-left: -8px; //Scrollbar padding fix
        }

        i.overview-modal-icon {
            text-align: center;
            min-width: 20px;
            margin-right: 10px;
        }
    }

    &.modal-h90 {
        .modal-content {
            height: 80vh;
            max-height: 80vh;
        }

        .modal-body {
            max-height: calc(80vh - 50px);
        }
    }

    .modal-body {
        max-height: calc(90vh - 136px);
        overflow-y: auto;
        overflow-x: hidden;

        @include media-breakpoint-up(md) {
            max-height: calc(70vh - 136px);
        }
    }

    //*** INNER STYLES ***//
    .modal-header {
        padding: 1rem 0 0 0;

        .close {
            padding: 0 0.2rem 0 0;
            margin: 0 0 0 auto;
            font-size: 21px;
        }

        &.modal-no-title {
            padding: 0.8rem 0;
        }
    }

    .modal-footer {
        justify-content: center;
    }
}


