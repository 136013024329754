.axle-part {
  line-height: 0;

  svg {
    line-height: 0;
  }
}

.axle-frame {
  // -webkit-filter: drop-shadow(12px 12px 7px rgba(0,0,0,0.5));
  // filter: drop-shadow(12px 12px 7px rgba(0,0,0,0.5));
}

@media screen and (-ms-high-contrast:active), (-ms-high-contrast:none) {
  .axle-frame {
    margin: -19px;
  }
}

.tire {
  &:hover {
    cursor: pointer;
    // filter: url(#blur-filter);
  }

  &.selected {
    // -webkit-filter: drop-shadow(12px 12px 7px rgba(0,0,0,0.5));
    // filter: drop-shadow(12px 12px 7px rgba(0,0,0,0.5));
  }
}

.tire-text {
  font-size: 18px;
  font-weight: bold;
  // fill: rgba(0,60,136,.5);

  &:hover {
    cursor: pointer;
  }
}

.axle-color {
  // fill: rgba(0,60,136,.5);
  fill: rgb(114, 150, 180);
}

.sensor-preview-box {
  fill: #ffffff;
  fill-opacity: 0.6;
  stroke: var(--ci-color-1);

  &:hover {
    cursor: pointer;
  }
}

.axle-tire-icon {
  &:hover {
    cursor: pointer;
  }
}

.tire-notification-icon {
  // fill: #ffffff;
  // fill-opacity: 0.6;
  // stroke: var(ci-color-1);

  &:hover {
    cursor: pointer;
  }
}

.notification-container {
  fill: #ffffff;
  fill-opacity: 0.6;
  stroke: var(ci-color-1);

  label {
    margin-bottom: 0;
  }
}

.ifms-axle-calibration {
  padding: 12px 0;

  .col {
    padding-right: 2px;
    padding-left: 2px;
  }

  .input-group-xs {
    // font-size: 12px;

    span {
      padding: 2px 3px;
    }

    input {
      padding: 2px 3px;
    }
  }
}

.ifms-calibration-number-input {
  input:required {
    box-shadow:none;
  }
}
