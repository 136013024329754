.person-details {
  display: flex;
  align-items: center;
  background: #eaeaea;
  flex-wrap: wrap;

  @include media-breakpoint-up(md) {
    flex-wrap: nowrap;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid $ci-color-7;
  }

  li {
    list-style-type: none;
    padding: 0 20px;
    font-size: 16px;
    line-height: 38px;
    cursor: default;
    overflow: hidden;
    width: 100%;

    i {
      display: none;

      &:hover {

      }
    }

    &:hover {
      //padding-left: 20px;
      i {
        display: inline-block;
        cursor: pointer;
        height: 100%;
        margin: 0 15px -17px -20px;
        font-size: 12px;
        color: white;
        background: $color-mekaniq;
        line-height: 39px;
        width: 39px;
        text-align: center;
      }
    }
  }
}

.person-selection-list {

  display: flex;
  flex-wrap: wrap;

  .buttons-group {
    display: block;
  }

  .icon {
    width: 60px;
    display: flex;
    text-align: center;
    align-self: center;
    margin: 12px 0 0 auto;
    @include media-breakpoint-down(sm) {
      position: absolute;
      right: 0;
      top: 0;
    }

    @include media-breakpoint-up(md) {
      margin: 0 0 4px 20px;
    }
  }

  i {
    width: 46px;
    padding: 15px;
    color: $white;
    background: $ci-color-11;
  }



  .person-selection-list-person {
    padding: 4px 12px;
    font-size: 16px;
    background: $white;
    border: 1px solid $ci-color-10;
    margin-bottom: 5px;
    cursor: pointer;

    &:hover {
      background: $ci-color10-lighten15;
    }
  }

  .person-info {
    padding: 21px 0 20px;

    @include media-breakpoint-up(md) {
      padding: 12px 0;
    }
  }

  .col-12:not(:last-of-type) .person-selection-list-person {
    border-bottom: 1px solid $ci-color-10;
  }
}


.person-list-tags {
  margin: 5px 0 14px 14px;
  display: flex;
  flex-wrap: wrap;

  @include media-breakpoint-up(sm) {
    flex-wrap: nowrap;
  }

  @include media-breakpoint-up(md) {
    margin: 0 0 0 auto;
    padding-right: 6px;
  }

  span {
    margin: 6px 6px 6px 0;
    background: red;
    padding: 4px 10px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 700;
    color: $color-white;
  }

  &.person-list-modal {
    border-top: 1px solid $ci-color-10;
    padding: 10px 0 5px;
    margin: 0;

    span {
      cursor: pointer;
    }

    @include media-breakpoint-down(md) {
      margin-left: 0;
    }
  }
}
