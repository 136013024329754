/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/scss/functions';
@import "variables";
@import '~bootstrap/scss/bootstrap';


@import '~font-awesome/css/font-awesome.min.css';
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~openlayers/dist/ol.css';
@import "fonts/ifms-icons/style.css";

@import 'partials/initial-spinner';

// compass mixins
//@import '../../node_modules/compass-mixins/lib/_compass.scss';

// base
@import 'shared/common';
@import 'modules/all';

// themes / brands
@import 'themes/themes';

@import 'primeng/themes/theme';

// vendor
@import 'vendor/tag-input';

// base ui components
@import 'partials/buttons';

// partials
@import 'partials/layout';
@import 'partials/forms';
@import 'partials/tables';
@import 'partials/panel';
@import 'partials/pagination';
@import 'partials/calendar-widget';
@import 'partials/modal';

@import 'components/all';

@import 'modules/angular2-notifications';
