@import "../../../src/styles/modules/colors";
@import '~bootstrap/scss/functions';
@import "variables";
@import "~bootstrap/scss/mixins";

.search-filters-container {
  align-items: flex-end;
  padding-bottom: 1rem;

  .d-flex {
    @include media-breakpoint-between(xs, md) {
      justify-content: center;
    }
  }

  button {
    background: #fff;
    border: 1px solid $color-mekaniq;
    color: $color-mekaniq;
    padding: 8px 16px;
    margin-bottom: 10px;

    &:hover {
      background: $color-mekaniq;
      color: #fff;
    }
  }


  .custom-odd-offset-3:nth-of-type(odd) {
    @include media-breakpoint-between(xs, sm) {
      margin-left: 25%;
    }
  }
}

