.btn-primary {
  background-color: $nx-btn-background;
  color: $nx-btn-font;
  border-color: $nx-btn-border;
  &:hover {
    background-color: $nx-btn-hover-background;
    color: $nx-btn-hover-font;
    border-color: $nx-btn-hover-border;
  }
}
