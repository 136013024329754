.map {
    height: 400px;
    width: 100%;
}

.map-container {
    position: relative;
}

.map-overlay {
    position: absolute;
    top: 0px;
    background: var(--ci-color-1);
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
}

.map-image {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
}

.map-image img {
    display: block;
    margin: 0 auto;
}

.map-image p {
    text-align: center;
    font-size: 18px;
}

.map-show-all-btn {
    position: absolute;
    bottom: 8px;
    left: 8px;
}

.ol-overlay-number-plate {
    margin-top: 20px;
    background: #ffffff;
    color: #000000;
    border: 2px solid #000000;
    padding: 2px 5px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
}
