tag {
    .tag__text {
        line-height: 29px;
        font-weight: 500;
    }

    delete-icon span svg path {
        fill: #888;
    }
}
