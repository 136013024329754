// depot view
.fleet-depot {
  @extend .fleet-units;
}

.fleet-depot__buttons {
  margin-top: 20px;
  margin-bottom: 20px;
}

.fleet-depot__container {
  @include media-breakpoint-up(lg) {
    height: calc(100vh - 360px);
    overflow-y: auto;
  }
}

.fleet-depot__list {

  .vehicles-table {
    .fa-coins {
      color: $ci-color-2;
    }
  }

  .vehicle-tables {
    overflow-y: auto;
  }

  thead tr th {
    position: sticky;
    top: 0;
    background: #FFFFFF;

    &:after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      width: 100%;
      height: $colored-border-thickness;
      background: $nx-table-header-border;
    }
  }
}

.fleet-depot__reports {
  margin-top: 20px;
  @include media-breakpoint-up(lg) {
    height: calc(100vh - 340px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}

// depot edit
.fleet-depot-edit__container {
  margin-top: 20px;
  @include media-breakpoint-up(lg) {
    height: calc(100vh - 280px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}



