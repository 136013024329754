.fleet-unit-link-controls {
  button {
    &:hover {
      cursor: pointer;
    }
  }
}

.fleet-unit-link-controls__btn-toolbar {
  flex-wrap: wrap;

  @include media-breakpoint-up(md) {
    flex-wrap: nowrap;
  }

  button {
    @include media-breakpoint-down(md) {
      margin: 0 0 0 auto;
    }
  }
}
