.vehicle-map {
  position: relative;

  .icon-gps {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 200;
    font-size: 24px;
    color: #f9a825;
  }
}
