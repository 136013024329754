@import '../../../../../node_modules/bootstrap/scss/functions';
@import "../../../bootstrap/variables";
@import "../../../../../node_modules/bootstrap/scss/mixins";
@import "../../../modules/colors";

.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  line-height: 1.8rem;
}

.custom-day.focused {
  background-color: #e6e6e6;
}

.custom-day.range, .custom-day:hover {
  background-color: $ci-color1-lighten10;
  color: white;
}

.custom-day.hover-whole-week {
  background-color: $ci-color1-darken20;
  color: white;
}

.custom-day.faded {
  background-color: $ci-color1-lighten20;
}

.ngb-dp-day.disabled span {
  color: red;
  background: none;
}

.custom-day.isFrom {
  background-color: $ci-color1-darken10;
}

.custom-day.isTo {
  background-color: $ci-color-1;
}

.custom-day.isFrom.isTo {
  background: $ci-color-1;
  background: linear-gradient(135deg, $ci-color1-darken10 50%, $ci-color-1 50%);
}

.calender-container {
  position: relative;

  .d-flex {
    justify-content: flex-end;
    margin: 0 0 6px;
  }

  * {
    border-radius: 0;
  }

  .date-time-picker {
    position: absolute;
    right: 7px;
    top: 35px;
    background: #fff;
    z-index: 100;
    padding: 20px;
    box-shadow: 0px 0px 10px 0px #ccc;

    .d-flex {
      justify-content: flex-end;
      align-items: center;
    }

    i {
      font-size: 22px;
    }

    button:not(.nx-btn) {
      background: #fff;
      color: $ci-color-1;
      outline: none;
      border: 1px solid $ci-color-9;
      padding: 0 16px;
    }

    button.nx-btn {
      padding: 6px 16px;
      margin-left: auto;
      height: auto;
      width: auto;
      line-height: normal;
      min-width: auto;
    }

    input {
      border-radius: 0;
      height: auto;
      border-color: $ci-color-9;
      padding: 3px 12px;

      &:focus {
        outline: none;
        box-shadow: none;
        border-color: $ci-color-9;
      }
    }
  }
}
