.support {

    .contact-data-table {
        margin: 20px 0;

        tr {
            td {
                font-size: 14px;

                &:first-child {
                    text-align: center;

                    i {
                        font-size: 30px;
                        color: $ci-color-3;
                    }
                }

                &:last-child {
                    padding-left: 10px;
                    font-size: 18px;
                }
            }
        }
    }

    .button-bar {
        text-align: center;
    }

    textarea {
        resize: none;
        width: 100%;
        border: 1px solid $ci-color-6;
        border-bottom: $colored-border-thickness solid $ci-color-5;
        background-color: white;
        font-size: $form-element-fontsize;
    }

}

// removed because header title is bugy on mobile
/*// Media queries
@media (max-width: 991px) {
    h1 {
        margin: 25px 0 15px 0;

        &:first-of-type {
            margin-top: 10px;
        }
    }
}*/
