.fleet-vehicle {

  @media (max-width: 991px) {
      .container {
          width: 100%;
          padding: 30px 15px;
      }
  }

}
