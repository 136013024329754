// common is used to share some variables of the theme with encapsulated component styles
// compass mixins
//@import '../../../node_modules/compass-mixins/lib/_compass.scss';

@import '../modules/colors';
@import '../modules/variables';

@import '~bootstrap/scss/functions';
@import "variables";
@import "~bootstrap/scss/mixins";

*:active, *:focus {
    outline: none;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}
.clearfix:after {
    clear: both;
}

.no-padding-left {
    padding-left: 0 !important;
}

.no-padding-right {
    padding-right: 0 !important;
}

.flex-grow {
    flex: 1 0 auto;
}

.noselect {
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.word-space-wrapper {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.ifms-icon {
  font-size: 24px;
}

.ifms-svg-inherit-image-color {
  fill: currentColor;
}

@supports (-webkit-overflow-scrolling: touch) {
  input, select, textarea {
    font-size: 16px !important;
  }
}


//Historical Trip Fake Table
.fake-table-head {
    border-bottom: 1px solid $color-mekaniq;
    color: $ci-color-7;
    padding-bottom: .5rem;
    display: none;

    @include media-breakpoint-up(md) {
        display: flex;
    }

    div {
        padding: 0 6px;
    }

    :nth-child(1) {
        width: 16%;

        @include media-breakpoint-up(lg) {
            width: 12%;
        }

        @include media-breakpoint-up(xl) {
            width: 10%;
        }
    }

    :nth-child(2) {
        width: 20%;
    }

    :nth-child(3) {
        width: 15%;
    }

    :nth-child(4) {
        width: 15%;
    }

    :nth-child(5) {
        width: 40%;
    }
}

.fake-table-inner {
    display: table;
    align-items: center;
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:nth-of-type(odd) {
        background: $ci-color-4;
    }

    &:hover {
        background: $nx-table-hover;
    }

    &.selected {
        background: $nx-table-hover;
        font-weight: 700;
    }

    .fake-table-col {
        padding: 8px 6px;
        display: table-cell;
        vertical-align: middle;

        &:last-child {
            display: none;

            @include media-breakpoint-up(md) {
                display: table-cell;
            }
        }
    }
}

button {
    &:focus,
    &:active {
        outline: none;
    }
}

body.modal-open {
    min-height: 100%;
    overflow: hidden;
}

body.mobile-menu-open {
    @include media-breakpoint-down(md) {
        min-height: 100%;
        overflow: hidden;
    }
}

.primary-color {
    color: $color-mekaniq;
}

select.primary {
    padding: 8px;
    display: block;
    margin: 0 0 8px auto;
    color: $ci-color-1;
    border: 1px solid $ci-color-1;
}
