.fleet-units {
  .nav-tabs.nav {
    margin-top: -12px;
    border-bottom: 1px solid $ci-color-1;

    @include media-breakpoint-down(xs) {
      border: 1px solid $ci-color-1;
    }

  }

  .nav-tabs .nav-item {
    height: 100%;

    @include media-breakpoint-down(xs) {
      width: 100%;
      text-align: center;
      margin-bottom: 1px;

      &:first-child {
        border-bottom: 1px solid $ci-color-1;
      }
    }
  }

  .nav-tabs .nav-link {
    h1 {
      border-bottom: none;
      padding-bottom: 0;
      margin: 0;
      opacity: 0.7;
    }

    &.active {
      border-color: transparent;

      @include media-breakpoint-up(lg) {
        border-color: $ci-color-1;
        border-bottom: 1px solid #ffffff;
      }

      h1 {
        opacity: 1;
      }
    }

    &:hover:not(.active) {
      border-color: transparent;
    }
  }
}

.fleet-units__vehicle-container {
  @include media-breakpoint-up(lg) {
    height: calc(100vh - 265px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.fleet-units__map {
  overflow-y: hidden;
  padding-top: 20px;
  width: 100%;
  height: auto;
}

.fleet-units__list {
  padding-top: 20px;
  overflow-x: auto;
}

.fleet-units__buttons {
  margin-top: 20px;

  i {
    margin-right: 7px;
  }
}
