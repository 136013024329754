@import 'colors';
@import 'fonts';
@import 'variables';

*:active, *:focus {
    outline: none;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}
.clearfix:after {
    clear: both;
}

.no-padding-left {
    padding-left: 0 !important;
}

.no-padding-right {
    padding-right: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.ui-widget-header {
    border-left: none;
    border-right: none;
    border-top: none;
}

.ui-radiobutton-icon {
    display: block;
    font-size: .6em !important;
    line-height: 17px;
    margin-left: -1px;
}

.ui-radiobutton-box {
    width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 100%;
    text-align: center;
}

.ui-radiobutton, .ui-radiobutton-label, .ui-chkbox-label {
    margin-bottom: 0;
}
