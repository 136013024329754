$unit-width: 196px;
$unit-height: 129px;

.fleet-units-grid {
  /* Grid Fallback */
  display: flex;
  flex-wrap: wrap;

  /* Supports Grid */
  // display: grid;
  // grid-template-columns: repeat(auto-fill, $unit-width);
  // // grid-template-columns: repeat(auto-fill, minmax($unit-width, 1fr));
  // grid-auto-rows: minmax($unit-height, auto);
  // grid-gap: 1em;
}

.fleet-unit-module {
  max-width: $unit-width;
  // max-height: $unit-height;

  /* Flex Fallback */
  margin: 4px;
  flex: 1 1 $unit-width;
}

/* If Grid is supported, remove the margin we set for the fallback */
@supports (display: grid) {
  // .fleet-unit-module {
  //   margin: 0;
  // }
}

@media (max-width: 447px) {
  .fleet-unit-module {
    max-width: 100%;
    max-height: 100%;

    /* Flex Fallback */
    margin: 4px;
    flex: 1 1 100%;
  }
}
