.search-results {

    .search-results-header {
        padding: 10px 0;
        border-bottom: 1px solid $ci-color-6;

        .results-info {
            font-weight: 300;
            line-height: 39px;
            vertical-align: middle;
            color: $ci-color-7;

            .count {
                color: $ci-color-1;
            }
        }

    }

}

.no-results {
    font-size: 20px;
    text-align: center;
    margin: 20px 0;
    color: $ci-color-7;
}

.dropdown-container {
    .label-text {
        padding-left: 10px;
    }
}

// Media queries
@media (max-width: 991px) {
    .mobile-table {
        padding-right: 0 !important;
    }
}

.ifms-trip-result-row {

  tr.selected {
    font-weight: bold;
    background-color: #e3f2fd !important;
  }

}
