.depot-marker {
  user-select: none;

  &:hover {
    cursor: pointer;
  }

}

.depot-group-list {
  list-style-type: none;
  padding-left: 0;

  li {
    margin: 0;
    padding: 4px 4px 4px 24px;
  }
}

.no-children {
  margin-left: 20px;
}

.ifms-tree-node {
  margin-bottom: 5px;
  user-select: none;
  display: inline-block;

  &:hover {
    color: $ci-color-1;
    font-weight: 900;
  }

}

.node-selected {
  color: $ci-color-1;
  font-weight: 900;
}
