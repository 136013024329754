.ifms-boxes-frame {
  padding: 7px 15px;
}

.ifms-box-card {
  display: inline-block;
  width: 200px;
  margin: 4px 3px;

  border-radius: 3px;
  border: 1px solid rgba(0,0,0,.03);
  box-shadow: 0 2px 2px rgba(0,0,0,.24), 0 0 2px rgba(0,0,0,.12);

  &:hover:not([disabled]) {
    cursor: pointer;
    background-color: transparent;
    background-color: rgba(0, 0, 0, 0.03);
  }

  .ifms-box-card-header {
    margin-top: 8px;
    margin-bottom: 8px;

    .card-property {
      // padding: 0px 16px;
      color: rgb(117, 117, 117);
      opacity: .87;

      @extend .word-space-wrapper;
    }

    .title {
      color: #212121;
      margin-bottom: 4px;
      font-weight: bold;
      font-size: 18px;
      opacity: .87;
    }

    .sub-title {

    }
  }

  .ifms-box-states {
    display: inline-block;
    margin-bottom: 8px;
  }

  &.horizontal-card {
    width: 100%;

    &:hover:not([disabled]) {
      cursor: default;
      background-color: inherit;
      background-color: inherit;
    }
  }
}

.selected-ifms-box {
  .ifms-box-card {
    width: 100% !important;
  }
}

@media (max-width: 447px) {
  ifms-box-card {
    width: 100%;
  }

  .ifms-box-card {
    margin: 3px 0;
    // width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
}

.ifsm-sensor-equipment {

  .ifms-box-card {
    width: 60px;
  }

}
