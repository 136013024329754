.one-time-login-failed {
  header {
    z-index: 20;
    position: fixed;
    margin-bottom: 10px;
    background: $ci-color-1;
    height: 80px;
    width: 100%;

    img {
      margin-top: 10px;
    }
  }

  .error-message {
    position: relative;
    height: 100vh;
    h1 {
      font-size: 28px;
      font-weight: 400;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
