.ifsm-user-management {

  .tabs-panel > {
      ul {
          li {
              width: 193px;
          }
      }
  }

  // Media queries
  @media (max-width: 991px) {
      .tabs-panel > {
          ul {
              li {
                  width: 193px;
              }
          }
      }
  }

  label:not(.toggle-switch) {
      line-height: normal;
      @include media-breakpoint-up(md) {
          height: 39px;
      }
  }

  .toggle-switch {
      margin-top: 10px;
  }

}
