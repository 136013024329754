@import 'roboto';

$fallback: sans-serif;
$main-font: 'Roboto', $fallback;
$condensed-font: 'Roboto Condensed', $fallback;
$font-size: 14px;

h1 {
    font-size: 24px;
    font-weight: 300;
    color: $ci-color-2;
    border-bottom: 1px solid $ci-color-5;
    padding-bottom: 5px;
}

label {
    color: $ci-color-2;
}
