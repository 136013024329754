/* use this file for all general layout styles, eg. body */

html {
    //position: relative;
    //min-height: 100%;
    //margin-left: calc(100vw - 100%); // fix for jumping scrollbar issue
    //overflow-y: scroll;
}

body {
    background-color: $nx-body-background;
    color: black;
    font-family: $main-font;
    font-weight: 300;
    font-size: $font-size;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: always;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    //padding-top: 90px;
    //margin: 0 0 ($footer-height + $footer-gap) 0; // sticky footer
}

footer {
    position: relative;
    left: 0;
    bottom: 0;
    height: $footer-height;
    line-height: $footer-height;
    width: 100%;
    background-color: $nx-footer-background;
    color: $nx-footer-color;
    text-align: center;
}

.mobile-label {
    font-weight: 500;
}

// Media queries
@media (max-width: 991px) {

    /*html {
        overflow-x: hidden !important;
    }*/

}
