.one-time-login {
  padding-bottom: 70px;
  header {
    z-index: 20;
    position: fixed;
    margin-bottom: 10px;
    height: 80px;
    width: 100%;
    border-bottom: 1px solid $ci-color-1;
    background: #fff;

    img {
      margin-top: 10px;
    }
  }

  .container {
    &.header-container {
      .logo {
        height: 60px;
      }
    }

    &:not(.header-container) {
      padding-top: 120px;
    }

    .fleet-btn {
      padding: 8px 24px;
      margin-bottom: 30px;
      color: $ci-color-1;
      border: 1px solid $ci-color-1;
      background: none;
      &.active {
        color: #ffffff;
        background-color: $ci-color-1;
      }
      &:hover {
        outline: none;
        opacity: 0.7;
      }
    }


    .fleet-unit-header {
      font-size: 18px;
      text-align: right;
      padding-top: 3px;
    }

    .axle-color {
      fill: #ffffff;
    }

    .tire *, .tire{
      cursor: default;
      user-select: none;
    }
  }
}
