.fleet-depot-tire-wheel-monitoring-report {
  .reports-download {
    .reports-tags {
      cursor: pointer;
      user-select: none;
      display: flex;
      flex-wrap: wrap;

      @include media-breakpoint-up(sm) {
        flex-wrap: nowrap;
      }

      @include media-breakpoint-up(md) {
        padding-right: 6px;
      }

      span {
        margin: 6px 6px 6px 0;
        background: red;
        padding: 4px 10px;
        border-radius: 12px;
        font-size: 12px;
        font-weight: 700;
        color: $color-white;

        &.disabled {
          user-select: none;
          pointer-events: none;
        }
      }

    }
    .calender-container {
      margin: 6px 6px;

      .date-time-picker {
        width: 515px;
      }

      .date-time-picker.show-weeks {
        width: 575px;
      }

      .d-flex {
        justify-content: flex-start !important;
      }

      .date-time-picker {
        left: 0;
      }
    }
    .date-time-picker {
      right: 0;
      left: auto !important;
    }
  }
}

.fleet-depot-tire-wheel-monitoring-report__billable_checkbox {
  label, input {
    margin: 10px 10px;
    position: relative;
    vertical-align: middle;
    bottom: 1px;
  }

  label {
    margin-left: 0;
    .fa-coins {
      color: $ci-color-2;
    }
  }
}

.reports-upload__modal {
  .modal-content {
    width: 500px;
    margin: 0 auto;
  }
}

.reports-download__logo_container {
  position: relative;
  display: block;
  width: 250px;
  height: 140.62px;
  margin-bottom: 20px;
}

.reports-download__logo_container:hover {
  .reports-download__logo_container__buttons_container {
    button {
      opacity: 1;
    }
  }

  .reports-download__logo_container__message_overlay {
    opacity: 1;
  }
}

.reports-download__logo_container__buttons_container {
  position: absolute;
  display: flex;
  width: 100%;
  bottom: 0;
  left: 0;

  button {
    min-width: 100px !important;
  }

  button {
    opacity: 0;
    transition: opacity 0.25s;
  }
}

.reports-download__logo_container__message_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 5px;
  background: #efefefe3;
  color: $ci-color-1;

  opacity: 0;
  transition: opacity 0.25s;
}


.reports-download__logo_container__brand_image {
  width: 100%;
  height: auto;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.reports-download__logo_container__edit {
  width: 50% !important;
}

.reports-download__logo_container__edit_100p {
  width: 100% !important;
}

.reports-download__logo_container__delete {
  width: 50% !important;
}

.year-month-picker {
  display: flex;
  align-items: center;

  select.primary {
    margin: 0 8px 0 3px;
  }
}



