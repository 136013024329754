/* use this file for general form styles, which can be reused all over the application */

.input-field {
    border: 1px solid $ci-color-6;
    border-bottom: $colored-border-thickness solid $ci-color-5;
    background-color: white;
    height: $form-element-height;
    font-size: $form-element-fontsize;
    width: 100%;
    padding: 0 10px;
    @include transition(all 0.30s ease-in-out);

    &:active, &:focus {
        outline: none;
        box-shadow: 0 0 5px $nx-input-focus-shadow;
        border: 1px solid $nx-input-focus-shadow;
    }

    &:disabled {
        background-color: #eeeeee;
    }

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        font-style: italic;
    }

    &::-moz-placeholder { /* Firefox 19+ */
        font-style: italic;
    }

    &:-ms-input-placeholder { /* IE 10+ */
        font-style: italic;
    }

    &:-moz-placeholder { /* Firefox 18- */
        font-style: italic;
    }

    &.ng-invalid {
        border-bottom-color: $color-error;

        &:active, &:focus {
            outline: none;
            box-shadow: 0 0 5px $color-error;
            border: 1px solid $color-error;
        }
    }
}


.form-input {
    label {
        color: $ci-color-1;
        font-size: 14px;
        margin-bottom: 3px;
    }

    input {
        @extend .input-field;
    }
}

.two-column-input {
    margin-bottom: 10px;

    & > label, & > div > div > label {
        color: $ci-color-1;
        font-size: 14px;
        margin-bottom: 3px;
        line-height: $form-element-height;
    }

    & > input, & > div > div > input {
        @extend .input-field;
    }
}

.ui-multiselect {
    border-radius: 0 !important;
    width: 100%;
    border-bottom: $colored-border-thickness solid $ci-color-5;

    .ui-multiselect-label {
        margin-bottom: 0 !important;
        height: $form-element-height - 3;
        width: 100%;
        border-radius: 0;
        line-height: 30px;
        padding-left: 10px;
    }

    .ui-multiselect-trigger {
        border-radius: 0 !important;
        width: 35px;
        border-left: 0;

        .fa {
            font-size: 18px;
            margin-top: 10px;
        }
    }
}

.quantity-input {
    height: 25px;
    line-height: 25px;
    width: 47px;
    text-align: right;
    padding-right: 5px;
}


// Media queries
@media (max-width: 991px) {
    .two-column-input {
        & > label, & > div > div > label {
            line-height: normal;
        }
    }
}

.no-whitespace-error-message {
  color: #d9534f;
  font-size: 10px;
  font-weight: 400;
  text-align: center;
}
