.tabs-panel > {
    ul {
        margin: 0;
        padding: 0;
        border-bottom: 1px solid $ci-color-6;
        list-style-type: none;
        
        // display: flex;
        // justify-content: space-evenly;

        display: flex;
        justify-content: space-between;

        &::before, &::after {
          content: "";
        }

        li {
            position: relative;
            line-height: $tab-height;
            text-align: center;
            color: $ci-color-1;
            text-transform: uppercase;

            .tab-title {
                &:hover {
                    cursor: pointer;
                }
            }

            &.active {
                border-bottom: 3px solid $ci-color-1;
                .tab-title {
                    font-weight: bold;
                }
            }

            &.disabled {
                color: red;

                .tab-title {
                    &:hover {
                        cursor: default;
                    }
                }

            }
        }
    }
}

@media (max-width: 991px) {
    .tabs-panel > {
        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            border-bottom: 0;
            border-top: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;

            li {
                position: relative;
                line-height: $tab-height;
                text-align: center;
                color: $ci-color-3;
                text-transform: uppercase;
                background-color: $ci-color-4;
                border: 1px solid $ci-color-6;
                width: 50%;

                &:last-child {
                    border-right: 1px solid $ci-color-6;
                }

                &.active {
                    background-color: white;
                    color: $ci-color-1;
                }
            }
        }
    }
}

@media (max-width: 451px) {
    .tabs-panel > {
        ul {
            display: flex;
            flex-direction: column;
            li {
                width: 100%;
            }
        }
    }
}
