.overview-container {
  .search-and-filter-container {
    background-color: #fafafa;
    position: fixed;
    z-index: 800;
    width: 100%;
    left: 0;
    top: 60px;
    padding-top: 10px;
    box-shadow: 0px 1px 20px #fafafa;

    @include media-breakpoint-up(lg) {
      top: 96px;
    }

    p {
      text-align: center;
      padding-top: 7px;
      cursor: pointer;
      color: $color-mekaniq;
      line-height: 1.2;

      &:hover {
        color: $ci-color1-darken30;
      }
    }

    .search-container {
      position: relative;
      background-color: #fafafa;
      z-index: 3;
      padding-top: 10px;
    }

    .filter-container {

      position: relative;
      z-index: 2;
      display: none;

      &.open-filter {
        display: block;
      }

      &.close-filter {
        display: none;
      }
    }
  }

  .tiles-container {
    padding-top: 90px;

    @include media-breakpoint-up(md) {
      padding-top: 90px;
    }

    @include media-breakpoint-up(lg) {
      padding-top: 100px;
    }


    &.open-filter {
      padding-top: 410px;

      @include media-breakpoint-up(md) {
        padding-top: 250px;
      }

      @include media-breakpoint-up(lg) {
        padding-top: 210px;
      }
    }

    &.close-filter {
      padding-top: 90px;

      @include media-breakpoint-up(md) {
        padding-top: 90px;
      }

      @include media-breakpoint-up(lg) {
        padding-top: 100px;
      }
    }

    .not-ok {
      //margin-bottom: 20px;
    }
  }
}

.modal-open {
  .overview-page-input {
    max-width: 100%;
    margin-left: -8px;
  }
}



.search-results {

    .search-results-header {
        padding: 10px 0;
        border-bottom: 1px solid $ci-color-6;

        .results-info {
            font-weight: 300;
            line-height: 39px;
            vertical-align: middle;
            color: $ci-color-7;

            .count {
                color: $ci-color-1;
            }
        }

    }

    .ifms-overview-unit-search {
        min-width: 100%;
        margin-top: 3px;
        padding: 6px;
        background-color: #ebebeb;

        position: fixed;
        z-index: 750;
    }

}

.no-results {
    font-size: 20px;
    text-align: center;
    margin: 20px 0;
    color: $ci-color-7;
}

.dropdown-container {
    .label-text {
        padding-left: 10px;
    }
}

// Media queries
@media (max-width: 991px) {
    .mobile-table {
        padding-right: 0 !important;
    }
}

@media (max-width: 447px) {
    .search-results {

        .ifms-overview-unit-search {
            min-width: calc(100% - 30px);
            margin-left: auto;
            margin-right: auto;
        }

    }
}

.fleet-recent-drives {
  .fleet-recent-drive {
    //border-radius: 3px;
    border: 1px solid rgba(0,0,0,.1);
    //box-shadow: 0 2px 2px rgba(0,0,0,.24), 0 0 2px rgba(0,0,0,.12);

    .fleet-unit-header {
      font-size: 18px;
      text-align: right;
    }

/*    &:hover {
      cursor: pointer;
      background-color: transparent;
      background-color: rgba(0, 0, 0, 0.1);
    }*/

    .drive-property {
      line-height: 21px;
      font-size: 10px;
      min-width: 54px;
      color: rgba(0,0,0,0.54);
    }

    .drive-content {
      line-height: 21px;
      font-size: 11px;

      // width: 132px;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }

    @include media-breakpoint-up(xxl) {
      @include make-col(3);
    }
  }
}
